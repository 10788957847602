import { signInWithRedirect } from '@aws-amplify/auth';

export function signUpWithGoogle() {
  signInWithRedirect({
    provider: 'Google',
  });
}

export function integrateWithGithub() {
  const clientId = 'Ov23liZ4mDZ5zkJu5Ww1';
  const redirectUri = 'http://localhost:3000/oauth/callback';
  window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=repo`;
}
