import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function LoginSuccessPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the main page after a successful login
    navigate('/');
  }, [navigate]);

  return (
    <div className="flex size-full flex-col items-center justify-center font-medium text-white">
      Login successful! Redirecting...
    </div>
  );
}
