import { useState } from 'react';
import { IntegrationsPage } from './pages/IntegrationsPage/IntegrationsPage';
import { ProjectPage } from './pages/ProjectPage/ProjectPage';
import { AccountPage } from './pages/AccountPage/AccountPage';

const SettingsPage = {
  ACCOUNT: 'Account',
  PROJECT: 'Project',
  INTEGRATIONS: 'Integrations',
};

interface Props {
  onClose: () => void;
}

export function SettingsModal(props: Props) {
  const [activePage, setActivePage] = useState(SettingsPage.ACCOUNT);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800">
      <div className="size-full max-w-3xl overflow-hidden rounded-lg bg-white shadow-lg">
        <div className="flex items-center justify-between border-b border-gray-200 p-4">
          <h2 className="text-xl font-semibold">Settings</h2>
          <button className="text-gray-500 hover:text-gray-700" onClick={props.onClose}>
            &times;
          </button>
        </div>
        <div className="flex h-full">
          <div className="h-full w-1/4 border-r border-gray-200">
            {Object.values(SettingsPage).map(setting => (
              <button
                className={`w-full p-4 text-left ${activePage === setting ? 'bg-gray-100 font-bold' : ''}`}
                onClick={() => setActivePage(setting)}
                key={setting}
              >
                {setting}
              </button>
            ))}
          </div>
          <div className="w-3/4 p-4">
            {activePage === SettingsPage.ACCOUNT && <AccountPage />}
            {activePage === SettingsPage.INTEGRATIONS && <IntegrationsPage />}
            {activePage === SettingsPage.PROJECT && <ProjectPage />}
          </div>
        </div>
      </div>
    </div>
  );
}
