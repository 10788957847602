import { RootState } from '../../../types/redux/RootState';
import { initialState } from './index';
import { createSelector } from '@reduxjs/toolkit';

const selectSlice = (state: RootState) => state?.user || initialState;

export const selectUser = createSelector([selectSlice], state => state);

export const selectUserStatus = createSelector([selectSlice], state => state.status);
export const selectUserId = createSelector([selectSlice], state => state.uuid);

export const selectUserLoggedIn = createSelector([selectSlice], state => state.uuid !== '');
