import { useEffect, useRef, useState } from 'react';
import { Button } from '../Button/Button';
import { launchApp } from '../../../helpers/launchApp';
import { useSelector } from 'react-redux';
import { selectProjectId } from '../../../stores/GlobalProject/slice/selectors';

export function Workspace() {
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const projectId = useSelector(selectProjectId);
  const [url, setUrl] = useState<null | string>(null);

  const onLaunchApp = () => {
    if (!projectId) return;
    launchApp(projectId).then(newUrl => setUrl(newUrl));
  };

  useEffect(() => {
    if (!iFrameRef.current) return;
    if (!url) return;
    iFrameRef.current.src = url;
  }, [url]);

  const onStopApp = () => {};

  return (
    <div className="_Wrapper flex size-full flex-col p-1.5">
      <div className="_HeaderMenu flex h-7 w-full gap-x-1">
        <Button onClick={onLaunchApp}>Launch</Button>
        <Button onClick={onStopApp}>Stop</Button>
      </div>
      <div className="_Viewport flex size-full grow flex-col items-center justify-center">
        {url ? (
          <iframe
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
            }}
            ref={iFrameRef}
            className="flex w-full grow bg-white"
            title="Viewport"
            sandbox="allow-scripts allow-same-origin allow-popups"
          />
        ) : (
          <p className="self-center text-[16px] font-medium text-white">Launch the app to get started.</p>
        )}
      </div>
    </div>
  );
}
