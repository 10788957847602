import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer } from '../../../utils/redux/redux-injectors';
import { AppState } from './types';

export const initialState: AppState = {};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
});

export const { actions: appActions } = slice;

export const useAppState = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
