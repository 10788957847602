export interface Integration {
  userId: string;
  username: string;
  accessToken: string;
}

/** Get user's list of integrations **/
export async function getUserIntegrations(userId: string): Promise<Array<Integration>> {
  return new Promise(async (resolve, reject) => {
    const apiUrl = 'https://7zoyh6jmlg.execute-api.us-east-1.amazonaws.com/Prod/user_integrations';

    try {
      if (userId === '') {
        reject('No user id!');
      }

      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_uuid: userId }),
      });

      if (!res.ok) {
        reject(`Error: ${res.status}`);
      }

      const data = await res.json();
      resolve(data);
    } catch (error) {
      console.error('Error:', error);
      reject(error);
    }
  });
}
