import { RootState } from '../../../types/redux/RootState';
import { initialState } from './index';
import { createSelector } from '@reduxjs/toolkit';

const selectSlice = (state: RootState) => state?.tasks || initialState;

const selectTasksSlice = createSelector([selectSlice], state => state);

export const selectTasks = createSelector([selectSlice], state => state.tasks);

export const selectTasksStatus = createSelector([selectSlice], state => state.status);
