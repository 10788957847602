import { Account } from '../Account/Account';
import { MenuOption } from './components/MenuOption/MenuOption';
import { useState } from 'react';
import { ChatPage } from './pages/ChatPage/ChatPage';
import { ComponentsPage } from './pages/ComponentsPage/ComponentsPage';
import { TasksPage } from './pages/TasksPage/TasksPage';

const MENU_OPTIONS = {
  CHAT: {
    icon: <div></div>,
    label: 'Chat',
  },
  COMPONENTS: {
    icon: <div></div>,
    label: 'Components',
  },
  TASKS: {
    icon: <div></div>,
    label: 'Tasks',
  },
};

export function Panel() {
  const [activeTab, setActiveTab] = useState<string>('Chat');

  let panelContent;
  switch (activeTab) {
    case 'Chat':
      panelContent = <ChatPage />;
      break;
    case 'Components':
      panelContent = <ComponentsPage />;
      break;
    case 'Tasks':
    default:
      panelContent = <TasksPage />;
  }

  return (
    <div className="_Panel flex size-full flex-col">
      <div className="_Menu flex h-7 w-full flex-row items-center justify-between border-b border-b-[#4C4C4C] px-2">
        <div className="_Left flex flex-row gap-0.25">
          {Object.values(MENU_OPTIONS).map(option => (
            <MenuOption key={option.label} onClick={() => setActiveTab(option.label)}>
              {option.label}
            </MenuOption>
          ))}
        </div>
        <div className="_Right flex">
          <Account />
        </div>
      </div>
      <div className="_Content w-full grow p-1.5">{panelContent}</div>
    </div>
  );
}
