/** Launch app and retrieve URL **/
export async function launchApp(projectId: string): Promise<string> {
  return new Promise(async (resolve, reject) => {
    const apiUrl = 'https://7zoyh6jmlg.execute-api.us-east-1.amazonaws.com/Prod/launch_app';

    try {
      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId }),
      });

      if (!res.ok) {
        throw new Error(`Error: ${res.status}`);
      }

      const data = await res.json();
      resolve(data.URL);
    } catch (error) {
      console.error('Error:', error);
      reject(error);
    }
  });
}
