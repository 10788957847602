import { signUpWithGoogle } from '../../../../../helpers/authHelper';
import { useSelector } from 'react-redux';
import { selectUserLoggedIn } from '../../../../../stores/GlobalUser/slice/selectors';

export function AccountPage() {
  const userLoggedIn = useSelector(selectUserLoggedIn);

  return (
    <div>
      <h3 className="mb-4 text-lg font-semibold">Account</h3>
      {!userLoggedIn && (
        <button onClick={signUpWithGoogle} className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600">
          Sign in with Google
        </button>
      )}
      {userLoggedIn && <div className="font-medium text-black">You are signed in with your Google account.</div>}
    </div>
  );
}
