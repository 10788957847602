import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { integrateGithub } from '../../../helpers/integrateGithub';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../stores/GlobalUser/slice/selectors';

export function IntegrationConfirmationPage() {
  const userId = useSelector(selectUserId);
  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const query = new URLSearchParams(location.search);
    const code = query.get('code');

    if (!code || !userId) return;
    integrateGithub(code, userId).finally(() => {
      navigate('/');
    });
  }, [navigate, userId]);

  return (
    <div className="flex size-full flex-col items-center justify-center font-medium text-white">
      Integration with service was successful! Redirecting you shortly.
    </div>
  );
}
