import { ResourcesConfig } from '@aws-amplify/core';

const isLocalhost = window.location.hostname === 'localhost';

const awsExports: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: 'us-east-1_CRwd1Vicp',
      userPoolClientId: '5i527vspp68nhfdqgsbmv4fv6r',
      loginWith: {
        oauth: {
          domain: 'aicode-app.auth.us-east-1.amazoncognito.com',
          scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [
            isLocalhost ? 'http://localhost:3000/login_successful' : 'https://aicode-services.com/login_successful',
          ],
          redirectSignOut: [isLocalhost ? 'http://localhost:3000/signout' : 'https://aicode-services.com/signout'],
          responseType: 'code',
          providers: ['Google'],
        },
      },
    },
  },
};

export default awsExports;
