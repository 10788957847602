import { useCallback, useEffect, useRef, useState } from 'react';
import { submitPrompt } from '../../../../../helpers/submitPrompt';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../../../stores/GlobalUser/slice/selectors';
import { getLatestConversation, Message } from '../../../../../helpers/getLatestConversation';
import { MessageBubble } from '../../../MessageBubble/MessageBubble';
import { selectProjectId } from '../../../../../stores/GlobalProject/slice/selectors';

export function ChatPage() {
  const userId = useSelector(selectUserId);
  const projectId = useSelector(selectProjectId);
  const [prompt, setPrompt] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [messages, setMessages] = useState<Array<Message>>([]);

  const updateConversation = useCallback(() => {
    if (!projectId) return;
    getLatestConversation(projectId)
      .then(conversation => setMessages(conversation.messages))
      .catch(() => {});
  }, [projectId]);

  const onSubmit = useCallback(() => {
    if (!textareaRef.current) return;
    if (!projectId) return;
    submitPrompt(prompt, projectId).then(() => updateConversation());
    setPrompt('');
    // Add message temporarily
    let newMessages = messages;
    const newMessage: Message = { role: 'user', content: prompt };
    newMessages.push(newMessage);
    textareaRef.current.focus();
    textareaRef.current.style.height = 'auto';
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [projectId, prompt, messages, updateConversation]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      const handleInput = () => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      textarea.addEventListener('input', handleInput);
      return () => textarea.removeEventListener('input', handleInput);
    }
  }, []);

  useEffect(() => {
    updateConversation();
  }, [updateConversation, userId]);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="flex size-full grow-0 flex-col justify-between gap-y-0.5">
      <div className="_MessagesWrapper flex h-2 w-full grow">
        <div className="_Messages flex w-full flex-col overflow-y-auto py-1">
          {messages
            .filter(message => message.role !== 'system')
            .map((message, index) => (
              <MessageBubble key={index} role={message.role} content={message.content} />
            ))}
          <div ref={messagesEndRef}></div>
        </div>
      </div>
      <div className="_SearchBar flex w-full items-center rounded-[12px] bg-[#2F2F2F] p-1.5 px-2.5">
        <textarea
          ref={textareaRef}
          className="grow cursor-text resize-none bg-transparent text-white outline-none placeholder:text-gray-400"
          style={{ caretColor: '#2763FF' }}
          placeholder="Search..."
          autoFocus
          value={prompt}
          onChange={event => setPrompt(event.target.value)}
          rows={1}
        />
        <div className="ml-2 flex cursor-pointer self-end text-white" onClick={onSubmit}>
          Submit
        </div>
      </div>
    </div>
  );
}
