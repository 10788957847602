import { Button } from '../../components/Button/Button';
import { useCallback, useEffect, useState } from 'react';
import { createNewProject } from '../../../helpers/createNewProject';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserId, selectUserLoggedIn, selectUserStatus } from '../../../stores/GlobalUser/slice/selectors';
import { signUpWithGoogle } from '../../../helpers/authHelper';
import { LoadingStatus } from '../../../stores/GlobalUser/slice/types';
import { Project } from '../../../types/project/types';
import { getProjects } from '../../../helpers/getProjects';

export function HomePage() {
  const navigate = useNavigate();
  const userId = useSelector(selectUserId);
  const userStatus = useSelector(selectUserStatus);
  const isUserLoggedIn = useSelector(selectUserLoggedIn);
  const isLoading = userStatus === LoadingStatus.LOADING || userStatus === LoadingStatus.IDLE;

  const [projects, setProjects] = useState<Array<Project>>([]);

  useEffect(() => {
    if (!userId) return;
    getProjects(userId).then(projects => {
      setProjects(projects);
    });
  }, [userId]);

  const createNewProjectFn = useCallback(() => {
    createNewProject(userId).then(projectId => {
      const projectUrl = 'project/' + projectId;
      navigate(projectUrl);
    });
  }, [navigate, userId]);

  return (
    <div className="_HomePage flex size-full flex-col items-center justify-center gap-1.5 p-1.5">
      {isLoading && <div className="text-[12px] font-medium text-white">Loading...</div>}
      {!isLoading && !isUserLoggedIn && (
        <button onClick={signUpWithGoogle} className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600">
          Sign in with Google
        </button>
      )}
      {!isLoading && isUserLoggedIn && (
        <div className="flex flex-col gap-y-3">
          <Button onClick={createNewProjectFn}>New Project</Button>
          <div className="flex flex-col gap-y-1">
            {projects.map(project => (
              <a
                key={project.project_id}
                className="_ProjectCard text-cen justify-center font-semibold text-white"
                href={'/project/' + project.project_id}
              >
                {project.project_name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
