import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from '../../../utils/redux/redux-injectors';
import { ProjectState } from './types';
import { Project } from '../../../types/project/types';
import { LoadingStatus } from '../../GlobalUser/slice/types';

export const initialState: ProjectState = {
  project: null,
  status: LoadingStatus.IDLE,
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProject(state, action: PayloadAction<Project>) {
      state.project = action.payload;
    },
    updateLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.status = action.payload;
    },
  },
});

export const { actions: projectActions } = slice;

export const useProjectState = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
