import { useLoadTasks } from '../../../../../stores/GlobalTasks/hooks/useLoadProject';
import { useSelector } from 'react-redux';
import { selectTasks } from '../../../../../stores/GlobalTasks/slice/selectors';
import { TaskView } from '../../../TaskView/TaskView';

export function TasksPage() {
  useLoadTasks();
  const tasks = useSelector(selectTasks);

  return (
    <div className="_TasksPage">
      {tasks.map(task => (
        <TaskView task={task} key={task.uuid} />
      ))}
    </div>
  );
}
