import { Route, Routes } from 'react-router-dom';
import { RoutePaths } from './constants';
import { HomePage } from '../../pages/HomePage';
import { LoginSuccessPage } from '../../pages/LoginSuccessPage/LoginSuccessPage';
import { ProjectPage } from '../../pages/ProjectPage';
import { IntegrationConfirmationPage } from '../../pages/IntegrationConfirmationPage/IntegrationConfirmationPage';

export function RoutesSwitch() {
  return (
    <Routes>
      <Route path={RoutePaths.Home} element={<HomePage />} />
      <Route path={RoutePaths.Project} element={<ProjectPage />} />
      <Route path={RoutePaths.LoginSuccessful} element={<LoginSuccessPage />} />
      <Route path={RoutePaths.GitHubCallback} element={<IntegrationConfirmationPage />} />
      <Route path="*" element={<div>Site not found!</div>} />
    </Routes>
  );
}
