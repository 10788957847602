import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from '../../../utils/redux/redux-injectors';
import { LoadingStatus, UserState } from './types';
import { UserAttributes } from '../../../helpers/getUserAttributes';

export const initialState: UserState = {
  uuid: '',
  name: '',
  email: '',
  picture: '',
  status: LoadingStatus.IDLE,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAttributes(state, action: PayloadAction<UserAttributes>) {
      console.info('User attributes set!');
      console.info(action.payload);
      state.uuid = action.payload.uuid;
      state.picture = action.payload.picture;
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    updateLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.status = action.payload;
    },
  },
});

export const { actions: userActions } = slice;

export const useUserState = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
