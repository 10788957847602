import { integrateWithGithub } from '../../../../../helpers/authHelper';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../../../stores/GlobalUser/slice/selectors';
import { useEffect, useState } from 'react';
import { getUserIntegrations, Integration } from '../../../../../helpers/getIntegrations';

export function IntegrationsPage() {
  const userId = useSelector(selectUserId);
  const [userIntegrations, setUserIntegrations] = useState<Array<Integration>>([]);

  useEffect(() => {
    getUserIntegrations(userId).then(integrations => setUserIntegrations(integrations));
  }, [userId]);

  return (
    <div className="flex w-full flex-col">
      <h3 className="mb-4 text-lg font-semibold">Integrations</h3>
      <button onClick={integrateWithGithub} className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600">
        Integrate with GitHub
      </button>

      {Object.values(userIntegrations).map(integration => (
        <div key={integration.userId} className="mt-3 w-full break-words">
          {JSON.stringify(integration)}
        </div>
      ))}
    </div>
  );
}
