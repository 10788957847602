import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { projectActions } from '../slice';
import { getProject } from '../../../helpers/getProject';
import { LoadingStatus } from '../../GlobalUser/slice/types';
import { selectProjectStatus } from '../slice/selectors';
import { useParams } from 'react-router-dom';

export function useLoadProject() {
  const params = useParams();
  const projectId = params.projectId;
  const dispatch = useDispatch();
  const projectStatus = useSelector(selectProjectStatus);

  useEffect(() => {
    if (projectStatus === LoadingStatus.IDLE && projectId) {
      getProject(projectId)
        .then(project => {
          if (!project) {
            dispatch(projectActions.updateLoadingStatus(LoadingStatus.FAILED));
            return;
          }
          dispatch(projectActions.setProject(project));
        })
        .catch(() => {
          dispatch(projectActions.updateLoadingStatus(LoadingStatus.FAILED));
        });
    }
  }, [dispatch, projectId, projectStatus]);
}
