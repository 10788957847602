import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { LoadingStatus } from '../../GlobalUser/slice/types';
import { selectTasksStatus } from '../slice/selectors';
import { tasksActions } from '../slice';
import { getTasks } from '../../../helpers/getTasks';
import { selectProjectId } from '../../GlobalProject/slice/selectors';

export function useLoadTasks() {
  const projectId = useSelector(selectProjectId);
  const dispatch = useDispatch();
  const tasksStatus = useSelector(selectTasksStatus);

  useEffect(() => {
    if (tasksStatus === LoadingStatus.IDLE && projectId) {
      getTasks(projectId)
        .then(tasks => {
          if (!tasks) {
            dispatch(tasksActions.updateLoadingStatus(LoadingStatus.FAILED));
            return;
          }
          dispatch(tasksActions.setTasks(tasks));
        })
        .catch(() => {
          dispatch(tasksActions.updateLoadingStatus(LoadingStatus.FAILED));
        });
    }
  }, [dispatch, projectId, tasksStatus]);
}
