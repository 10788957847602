import { useEffect, useRef, useState } from 'react';
import { getUserAttributes } from '../../../helpers/getUserAttributes';
import { SettingsModal } from '../SettingsModal/SettingsModal';
import { Modal } from '../Modal';

export function Account() {
  const pictureRef = useRef<HTMLImageElement | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState<string | null>(null);
  const [picture, setPicture] = useState<string | null>(null);

  useEffect(() => {
    async function fetchUserAttributes() {
      const attr = await getUserAttributes();
      if (!attr) return;
      setName(attr.name);
      setPicture(attr.picture);
    }
    fetchUserAttributes();
  }, []);

  return (
    <>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <SettingsModal onClose={() => setModalOpen(false)} />
        </Modal>
      )}
      <div
        className="_Account flex size-3 cursor-pointer self-end rounded-[100px] bg-[#2F2F2F]"
        onClick={() => setModalOpen(!modalOpen)}
      >
        {picture && (
          <img
            ref={pictureRef}
            src={picture}
            alt="user profile"
            className="size-3 overflow-hidden rounded-[100px]"
            onError={() => setPicture(null)}
          />
        )}
      </div>
    </>
  );
}
