import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  onClick: () => void;
}

export function MenuOption(props: Props) {
  return (
    <div
      className="flex cursor-pointer flex-col items-center justify-center p-1 text-[14px] font-medium text-white"
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
