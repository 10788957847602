import { Task } from '../stores/GlobalTasks/slice/types';

/** Get all tasks tied to a project **/
export async function getTasks(projectId: string): Promise<Array<Task> | null> {
  return new Promise(async (resolve, reject) => {
    const apiUrl = 'https://7zoyh6jmlg.execute-api.us-east-1.amazonaws.com/Prod/get_tasks';

    try {
      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId }),
      });

      if (!res.ok) {
        throw new Error(`Error: ${res.status}`);
      }

      const data = await res.json();
      resolve(data);
    } catch (error) {
      console.error('Error:', error);
      reject(error);
    }
  });
}
