export interface Message {
  role: 'user' | 'assistant' | 'system';
  content: string;
}

export interface Conversation {
  _id: string;
  user_uuid: string;
  messages: Array<Message>;
  updated_at: string;
}

/** Fetches latest conversation **/
export async function getLatestConversation(projectId: string): Promise<Conversation> {
  return new Promise(async (resolve, reject) => {
    const apiUrl = 'https://7zoyh6jmlg.execute-api.us-east-1.amazonaws.com/Prod/latest_conversation';

    try {
      if (projectId === '' || !projectId) {
        reject('No project id!');
        return;
      }

      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId }),
      });

      if (!res.ok) {
        reject(`Error: ${res.status}`);
        return;
      }

      const data = await res.json();
      if (!data['messages']) {
        reject('No conversation found!');
      } else {
        resolve(data);
      }
    } catch (error) {
      console.error('Error:', error);
      reject(error);
    }
  });
}
