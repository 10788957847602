import { useEffect, useState } from 'react';
import { setProjectSettings } from '../../../../../helpers/setProjectSettings';
import { Input } from '../../../Input/Input';
import { useSelector } from 'react-redux';
import { selectProject, selectProjectId } from '../../../../../stores/GlobalProject/slice/selectors';

export function ProjectPage() {
  const projectId = useSelector(selectProjectId);
  const project = useSelector(selectProject);
  const [frontendRepo, setFrontendRepo] = useState('');
  const [backendRepo, setBackendRepo] = useState('');

  useEffect(() => {
    if (project?.settings?.frontend_repo_url) setFrontendRepo(project?.settings.frontend_repo_url);
    if (project?.settings?.backend_repo_url) setBackendRepo(project?.settings.backend_repo_url);
  }, [project, project?.settings?.backend_repo_url, project?.settings?.frontend_repo_url]);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!projectId) return;
    setProjectSettings(projectId, { frontend_repo_url: frontendRepo, backend_repo_url: backendRepo });
  };

  return (
    <div className="container mx-auto">
      <h3 className="mb-4 text-lg font-semibold">Project Settings</h3>
      <form onSubmit={handleSubmit}>
        <Input
          label="Frontend Repository URL"
          value={frontendRepo}
          onChange={e => setFrontendRepo(e.target.value)}
          placeholder="Enter the frontend repository URL"
        />
        <Input
          label="Backend Repository URL"
          value={backendRepo}
          onChange={e => setBackendRepo(e.target.value)}
          placeholder="Enter the backend repository URL"
        />
        <button
          type="submit"
          className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
