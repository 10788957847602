import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from '../../../utils/redux/redux-injectors';
import { LoadingStatus } from '../../GlobalUser/slice/types';
import { Task, TasksState } from './types';

export const initialState: TasksState = {
  tasks: [],
  status: LoadingStatus.IDLE,
};

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasks(state, action: PayloadAction<Array<Task>>) {
      state.tasks = action.payload;
    },
    updateLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.status = action.payload;
    },
  },
});

export const { actions: tasksActions } = slice;

export const useTasksState = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
