import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  onClick: () => void;
}

export function Button(props: Props) {
  return (
    <div
      className="_Button flex h-5 w-full max-w-20 cursor-pointer flex-row items-center rounded-[12px] bg-white p-2"
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
