import { useDispatch, useSelector } from 'react-redux';
import { selectUserStatus } from '../slice/selectors';
import { useEffect } from 'react';
import { LoadingStatus } from '../slice/types';
import { getUserAttributes } from '../../../helpers/getUserAttributes';
import { userActions } from '../slice';

export function useLoadUser() {
  const dispatch = useDispatch();
  const userStatus = useSelector(selectUserStatus);

  useEffect(() => {
    if (userStatus === LoadingStatus.IDLE) {
      dispatch(userActions.updateLoadingStatus(LoadingStatus.LOADING));
      getUserAttributes()
        .then(attributes => {
          if (!attributes) {
            dispatch(userActions.updateLoadingStatus(LoadingStatus.FAILED));
            return;
          }
          dispatch(userActions.setUserAttributes(attributes));
          dispatch(userActions.updateLoadingStatus(LoadingStatus.SUCCEEDED));
        })
        .catch(() => {
          dispatch(userActions.updateLoadingStatus(LoadingStatus.FAILED));
        });
    }
  }, [dispatch, userStatus]);
}
