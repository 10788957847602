interface Props {
  label: string;
  value: string;
  onChange: (e: any) => void;
  placeholder: string;
}

export function Input(props: Props) {
  return (
    <div className="mb-4">
      {props.label && <label className="mb-2 block text-sm font-bold text-gray-700">{props.label}</label>}
      <input
        type="text"
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
      />
    </div>
  );
}
