import { Task } from '../../../stores/GlobalTasks/slice/types';

interface Props {
  task: Task;
}

export function TaskView(props: Props) {
  const task = props.task;

  return <div className="_TaskView h-6 w-full bg-ozoneV2-grey-900">{task.name}</div>;
}
