import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  onClose?: () => void;
}

export function Modal(props: Props) {
  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Prevent onClose from being called when clicking inside the modal
    if (e.target === e.currentTarget) {
      props.onClose?.();
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-15"
      onClick={handleBackgroundClick}
    >
      <div className="_Modal fixed left-1/2 top-1/2 flex min-h-[520px] w-90 -translate-x-1/2 -translate-y-1/2 rounded-[16px] bg-[#2A2A2A]">
        {props.children}
      </div>
    </div>
  );
}
