import { RootState } from '../../../types/redux/RootState';
import { initialState } from './index';
import { createSelector } from '@reduxjs/toolkit';

const selectSlice = (state: RootState) => state?.project || initialState;

const selectProjectSlice = createSelector([selectSlice], state => state);

export const selectProject = createSelector([selectSlice], state => state.project);
export const selectProjectId = createSelector([selectSlice], state => state.project?.project_id ?? null);

export const selectProjectStatus = createSelector([selectSlice], state => state.status);
