interface Props {
  role: 'assistant' | 'system' | 'user';
  content: string;
}

export function MessageBubble({ role, content }: Props) {
  const isUser = role === 'user';

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-1`}>
      <div className={`rounded-[12px] p-1 ${isUser ? 'bg-blue-500 text-white' : 'bg-gray-600 text-white'} max-w-xs`}>
        {content}
      </div>
    </div>
  );
}
