export enum LoadingStatus {
  IDLE,
  LOADING,
  SUCCEEDED,
  FAILED,
}

export interface UserState {
  uuid: string;
  email: string;
  name: string;
  picture: string;
  status: LoadingStatus;
}
