import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { GlobalStores } from '../stores';
import { GlobalStyle } from './style/global-styles';
import { RoutesSwitch } from './components/RoutesSwitch/RoutesSwitch';
import { useLoadUser } from '../stores/GlobalUser/hooks/useLoadUser';

export function App() {
  const { i18n } = useTranslation();
  useLoadUser();

  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s" defaultTitle="Code AI" htmlAttributes={{ lang: i18n.language }}>
        <meta name="description" content="AI OS" />
      </Helmet>

      <GlobalStores />
      <GlobalStyle />
      <RoutesSwitch />
    </BrowserRouter>
  );
}
