import { Panel } from '../../components/Panel/Panel';
import { useLoadProject } from '../../../stores/GlobalProject/hooks/useLoadProject';
import { Workspace } from '../../components/Workspace/Workspace';

export function ProjectPage() {
  useLoadProject();

  return (
    <div className="_ProjectPage relative flex size-full flex-col gap-1.5 p-1.5">
      <div className="_Wrapper flex size-full flex-row gap-1.5">
        <div className="_Panel flex h-full w-52 rounded-[12px] bg-[#272727]">
          <Panel />
        </div>
        <div className="_Workspace flex h-full grow rounded-[12px] bg-[#272727]">
          <Workspace />
        </div>
      </div>
    </div>
  );
}
