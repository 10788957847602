import { GlobalApp } from './GlobalApp';
import { GlobalUser } from './GlobalUser';
import { GlobalTasks } from './GlobalTasks';
import { GlobalProject } from './GlobalProject';

export function GlobalStores() {
  return (
    <>
      <GlobalApp />
      <GlobalUser />
      <GlobalTasks />
      <GlobalProject />
    </>
  );
}
